var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-50"
  }, [_c('BTable', {
    staticClass: "m-0 bg-white rounded-8px",
    attrs: {
      "items": _vm.priceList.fareOpts,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column,
            staticClass: "text-dark text-nowrap",
            class: "".concat(['netFare', 'taxFare', 'totalFare'].includes(column) ? 'text-right' : 'text-center')
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.combinationTab.table.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(paxType)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(item.paxType))) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.amount) + " ")])];
      }
    }, {
      key: "cell(netFare)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.html.v-primary.window",
            modifiers: {
              "hover": true,
              "html": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "text-nowrap position-relative",
          attrs: {
            "title": "Gi\xE1 v\xE9: ".concat(_vm.formatCurrency(item.fare), "<br> Chi\u1EBFt kh\u1EA5u: ").concat(_vm.formatCurrency(item.promotion), "<br> ").concat(_vm.source === '1G' ? 'Chiết khấu hãng: Vui lòng xem bảng chiết khấu BSP - Hoặc liên hệ booker, sales' : "Khuy\u1EBFn m\u1EA1i h\xE3ng: ".concat(_vm.formatCurrency(item.discount)))
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceFareCol)) + " "), item.priceDiscountFareCol > 0 ? _c('span', {
          staticClass: "badge badge-up badge-pill bg-danger badge-glow text-nowrap discount-badge"
        }, [_vm._v(" " + _vm._s("".concat(Math.ceil(item.priceDiscountFareCol / item.fare * 100), "%")) + " ")]) : _vm._e()]), _c('div', [(item.discount || item.promotion) && _vm.getShowPriceAfterDiscount ? _c('del', {
          staticClass: "text-secondary",
          class: _vm.isMobileView ? 'font-small-2' : 'font-small-4'
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.fare)) + " ")]) : _vm._e()])])];
      }
    }, {
      key: "cell(taxFare)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.html.v-primary.window",
            modifiers: {
              "hover": true,
              "html": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "text-nowrap",
          attrs: {
            "title": "Thu\u1EBF v\xE0 ph\xED: ".concat(_vm.formatCurrency(item.tax + item.surcharge + (!_vm.getShowServiceFee && _vm.getShowPrice !== 'NET_FARE' ? item.serviceFee : 0)), " ").concat(_vm.getShowServiceFee ? "<br>Ph\xED d\u1ECBch v\u1EE5: ".concat(_vm.formatCurrency(item.serviceFee)) : '', " ").concat(_vm.getShowAgencyFee && item.title !== 'INF' ? "<br>Ph\xED xu\u1EA5t v\xE9: ".concat(_vm.formatCurrency(item.agencyFee)) : '')
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceFeeCol)) + " ")])])];
      }
    }, {
      key: "cell(totalFare)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-danger text-right fw-700"
        }, [_c('span', {
          class: "text-nowrap ".concat(item.discount ? 'text-success' : '')
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceTotalCol)) + " ")]), _c('div', [(item.discount || item.promotion) && _vm.getShowPriceAfterDiscount ? _c('del', {
          staticClass: "text-secondary",
          class: _vm.isMobileView ? 'font-small-2' : ''
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceTotalCol + item.discount + item.promotion)) + " ")]) : _vm._e()])])];
      }
    }], null, true)
  }), _vm.isVisiblePriceFareCol && _vm.priceList.total ? _c('div', {
    staticClass: "text-danger d-flex-center justify-content-end gap-1 my-50"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Tổng tiền:")]), _c('span', {
    staticClass: "fw-800 font-medium-5 "
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.priceList.total)))])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }